<template>
  <div>
    <v-container v-if="componentLoading">
      <v-row justify="center">
        <v-col xs="12" lg="6">
          <h3>
            Deal List {{ listType }}
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </h3>
        </v-col>
      </v-row>
    </v-container>

    <v-container v-if="!componentLoading">
      <v-row justify="center">
        <v-col xs="12" xl="8">
          <h3>Deal List {{ listType }}</h3>
          <br />
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
          <br />
          <v-data-table
            @click:row="handleClick"
            :headers="headers"
            :items="dealList"
            :search="search"
            :items-per-page="-1"
          >
            <template v-slot:[`item.province`]="{ item }">
              {{ item.province | capitalize }}</template
            >
            <template v-slot:[`item.amountRequested`]="{ item }">
              {{ item.amountRequested | currency }}</template
            >
            <template v-slot:[`item.status`]="{ item }">
              {{ item.status | capitalize_first_letter }}</template
            >
            <template v-slot:[`item.loan_to_value`]="{ item }">
              {{ item.ltv }}%</template
            >
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import API from '@/plugins/API';
import filters from '@/plugins/filters';
export default {
  name: 'DealRead',
  props: ['dealId'],
  data() {
    return {
      listType: null,
      search: '',
      headers: [
        {
          text: 'Public Name',
          align: 'start',
          filterable: true,
          value: 'public_name',
        },
        {
          text: 'Internal Name',
          align: 'start',
          filterable: true,
          value: 'internal_name',
        },

        //  {
        //   text: "Request",
        //   align: "start",
        //   filterable: true,
        //   value: "deal_summary.amount_requested",
        // },
        //  {
        //   text: "City",
        //   align: "start",
        //   filterable: true,
        //   value: "deal_summary.city",
        // },
        {
          text: 'Province',
          align: 'start',
          filterable: true,
          value: 'province',
        },
        {
          text: 'Status',
          align: 'start',
          filterable: true,
          value: 'status',
        },
        {
          text: 'Deal Summary Status',
          align: 'start',
          filterable: true,
          value: 'deal_summary_status',
        },
        // {
        //   text: "City",
        //   value: "city",
        // },
        // {
        //   text: "Province",
        //   value: "province",
        // },
        // {
        //   text: "LTV",
        //   value: "ltv",
        // },
        // {
        //   text: "Requested",
        //   value: "amountRequested",
        // },
        // {
        //   text: "Status",
        //   value: "status",
        // },
      ],
      componentLoading: true,
      error: '',
      dealList: [],
      active_deal_summaries: [],
    };
  },
  filters: {
    ...filters,
  },
  methods: {
    handleClick(value) {
      this.$router.push(`/dashboard/admin/deal/read/${value.id}`);
    },
  },
  watch: {
    '$route.params.status'(val) {
      // call the method which loads your initial state
      this.find();
    },
  },
  mounted() {
    console.log('mounted');
    // console.log('route query', this.$route.query)

    if (this.$route.query.status == 'all') {
      this.listType = '- All';
    } else if (this.$route.query.status == 'active') {
      this.listType = '- Active';
    } else if (this.$route.query.status == 'awarded') {
      this.listType = '- Awarded';
    } else if (this.$route.query.status == 'funded') {
      this.listType = '- Funded';
    } else if (this.$route.query.status == 'inactive') {
      this.listType = '- Inactive';
    } else if (this.$route.query.status == 'archive') {
      this.listType = '- Archived';
    }

    API()
      .get(`/api/internal-admin/getAllDeals?status=${this.$route.query.status}`)
      .then((res) => {
        console.log(res);
        this.dealList = res.data;

        this.dealList.forEach((el) => {
          const active_deal_summaries = [];

          el.deal_summaries.forEach((el) => {
            if (el.status === 'active') {
              active_deal_summaries.push({ id: el.id });
            }
          });

          active_deal_summaries.length !== 0
            ? (el.deal_summary_status = 'Active')
            : (el.deal_summary_status = 'Inactive');

          console.log(active_deal_summaries);
        });

        this.componentLoading = false;
      })
      .catch((e) => {
        console.log('error');
        console.log(e);
      });
  },
};
</script>

<style></style>
